@import './variables';
@import '../common/mixins';

%text {
	font-weight: 400;

	&_bold {
		font-weight: 600;
	}
}

%link {
	@extend %text;
	@include fastTransition(color, 0.2s);

	color: $brandBlueColor;

	&_bold {
		@extend %text_bold;
	}

	&:hover {
		color: $brandOrangeColor;
	}
}
