@import '../variables';
@import '../../common/mixins';
@import '../../common/_variables.sass';
@import '../blocks';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import '../_footer.scss';
//@import '../index';

@font-face {
	font-family: 'CaviarDreams';
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsRegular.eot');
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsRegular.eot?#iefix')
			format('embedded-opentype'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsRegular.woff') format('woff'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsRegular.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'CaviarDreams';
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBold.eot');
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBold.eot?#iefix')
			format('embedded-opentype'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBold.woff') format('woff'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBold.ttf') format('truetype');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'CaviarDreams';
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsItalic.eot');
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsItalic.eot?#iefix')
			format('embedded-opentype'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsItalic.woff') format('woff'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'CaviarDreams';
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBoldItalic.eot');
	src: url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBoldItalic.woff') format('woff'),
		url('//booking.flyaurora.ru/resources/websky_grt/custom/fonts/CaviarDreamsBoldItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: bold;
}

body {
	background-color: #fff;
	font-family: 'Open Sans', sans-serif;
}

.btn.btn_search,
.webRegistration .btn_next,
.popup_webRegistration .popup__i__webReg__dataPassenger,
.popup__i__footer__buttons__button {
	background: rgb(241, 133, 0);
	color: #fff;
	transition: background 0.1s ease-in-out;
}

.searchSteps ol li.prev a,
.searchSteps ol li.prev span,
.regSteps ol li.prev a,
.regSteps ol li.prev span {
	background: #e5e5e5;
}

.regSteps ol li.prev a:hover {
	background: #e5e5e5;
}

.btn.btn_search:hover,
.webRegistration .btn_next:hover,
.popup_webRegistration .popup__i__webReg__dataPassenger,
.popup__i__footer__buttons__button:hover {
	background: #002c57;
}

.regSteps {
	background: #000;
}

.regSteps ol li.active a,
.regSteps ol li.active {
	background: #f18500;
	color: #ffffff;
}

.regSteps ol li a:hover {
	background: #f18500;
}

.regSteps ol li.active:after {
	color: #fff;
	border-color: #fff;
}

.regSteps ol li:after {
	width: 24px;
	height: 24px;
	line-height: 20px;
	border: 2px solid #ffffff;
	margin-top: -2px;
}

.regSteps ol li,
.regSteps ol li a {
	color: #fff;
}

/*
	when registration step === 3
	style step 2 like inactive
*/
.regSteps__i ol li.prev + li:not(.active) {
	opacity: 0.6;
	cursor: not-allowed;
	position: relative;
}

/* register */
.seatSelect .seat.active,
.seatSelect .seat.seat-selected,
.seatSelect .seat.seat-order,
.seatSelect .info ul li.selected span {
	background: #f18500;
}

/* popup (passenger data) */
.popup_webRegistration .popup__i__webReg__dataPassenger,
.popup__i__footer__buttons__button {
	background: #f18500;
}

.popup_webRegistration .popup__i__webReg__dataPassenger__name,
.popup_webRegistration .popup__i__webReg__dataPassenger .pass-data-empty,
.pass-document {
	color: #ffffff;
}

.passengerDataSeat__tabs__item.passenger-item-active {
	background: #ffffff;
}

/* end of register */

/* boardingPass */

.tooltip-cont .tooltip .tooltip-inner {
	background: #f18500;
}

.tooltip-cont .tooltip svg.svg-symbol-tooltip-arrow * {
	fill: #f18500;
}

/* end of boardingPass */

@media only screen and (max-width: 837px) {
	.searchSteps ol li,
	.regSteps ol li {
		width: 50%;
		box-sizing: border-box;
		text-align: center;
	}

	.regSteps ol li:nth-child(3):after {
		padding-left: 0;
	}

	.regSteps ol li:nth-child(4):after {
		padding-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.searchSteps ol li,
	.regSteps ol li {
		width: 25%;
	}

	.regSteps ol li:after {
		line-height: 20px;
	}

	.regSteps ol li:after {
		margin-top: 0;
		border: 0;
	}
}

body {
	background-color: #fff;
	font-family: 'Open Sans', sans-serif;
}

.btn.btn_search,
.webRegistration .btn_next,
.popup_webRegistration .popup__i__webReg__dataPassenger,
.popup__i__footer__buttons__button {
	background: rgb(241, 133, 0);
	color: #fff;
	transition: background 0.1s ease-in-out;
}

.searchSteps ol li.prev a,
.searchSteps ol li.prev span,
.regSteps ol li.prev a,
.regSteps ol li.prev span {
	background: #e5e5e5;
}

.regSteps ol li.prev a:hover {
	background: #e5e5e5;
}

.btn.btn_search:hover,
.webRegistration .btn_next:hover,
.popup_webRegistration .popup__i__webReg__dataPassenger,
.popup__i__footer__buttons__button:hover {
	background: #002c57;
}

.regSteps {
	background: #000;
}

.regSteps ol li.active a,
.regSteps ol li.active {
	background: #f18500;
	color: #ffffff;
}

.regSteps ol li a:hover {
	background: #f18500;
}

.regSteps ol li.active:after {
	color: #fff;
	border-color: #fff;
}

.regSteps ol li:after {
	width: 24px;
	height: 24px;
	line-height: 20px;
	border: 2px solid #ffffff;
	margin-top: -2px;
}

.regSteps ol li,
.regSteps ol li a {
	color: #fff;
}

/*
	when registration step === 3
	style step 2 like inactive
*/
.regSteps__i ol li.prev + li:not(.active) {
	opacity: 0.6;
	cursor: not-allowed;
	position: relative;
}

/* register */
.seatSelect .seat.active,
.seatSelect .seat.seat-selected,
.seatSelect .seat.seat-order,
.seatSelect .info ul li.selected span {
	background: #f18500;
}

/* popup (passenger data) */
.popup_webRegistration .popup__i__webReg__dataPassenger,
.popup__i__footer__buttons__button {
	background: #f18500;
}

.popup_webRegistration .popup__i__webReg__dataPassenger__name,
.popup_webRegistration .popup__i__webReg__dataPassenger .pass-data-empty,
.pass-document {
	color: #ffffff;
}

.passengerDataSeat__tabs__item.passenger-item-active {
	background: #ffffff;
}

/* end of register */

/* boardingPass */

.tooltip-cont .tooltip .tooltip-inner {
	background: #f18500;
}

.tooltip-cont .tooltip svg.svg-symbol-tooltip-arrow * {
	fill: #f18500;
}

/* end of boardingPass */

@media only screen and (max-width: 837px) {
	.searchSteps ol li,
	.regSteps ol li {
		width: 50%;
		box-sizing: border-box;
		text-align: center;
	}

	.regSteps ol li:nth-child(3):after {
		padding-left: 0;
	}

	.regSteps ol li:nth-child(4):after {
		padding-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.searchSteps ol li,
	.regSteps ol li {
		width: 25%;
	}

	.regSteps ol li:after {
		line-height: 20px;
	}

	.regSteps ol li:after {
		margin-top: 0;
		border: 0;
	}
}

/* ==== #37107 ==== */

.modal {
	&-header {
		&-inner {
			background: $brandOrangeColor;
		}
	}
}

#checkinForm {
	.twisted-order-cont {
		.btn-goto-segment {
			box-sizing: border-box;
		}
	}
}

.searchSteps ol li.active a,
.searchSteps ol li.active span,
.regSteps ol li.active a,
.regSteps ol li.active span {
	@include adaptivity(tablet) {
		padding: 18px 19px 18px 40px;
	}
}

.popover {
	.popover {
		&-inner {
			background: $brandOrangeColor;
		}
	}

	svg.svg-symbol-popover-arrow * {
		fill: $brandOrangeColor;
	}
}

.webRegistration .registration__passengers_ready .route .item .passenger {
	.data_link,
	.cancel__link {
		color: #ffffff;
	}

	.passenger_lnk {
		background: $brandOrangeColor;
		color: #ffffff;
	}
}

.popup__i__webReg__dataPassenger {
	list-style-type: none;
}

.webRegistration .registration__passengers_ready .route .item .passenger {
	.mail__link {
		background: $ic-mail-white no-repeat center center;
		background-size: 60%;
		border-color: #ffffff;
	}

	.qr__link {
		background: $ic-qrCode-white no-repeat center center;
		border-color: #ffffff;
	}

	.print__link {
		background: $ic-print-white no-repeat center center;
		background-size: 60%;
		border-color: #ffffff;
	}

	.dropPassenger__passenger__header {
		background: #f18401;
		color: white;
	}
}

/* ==== END #37107 ==== */

/* ==== 44943 ==== */

.header {
	background: #ffffff;
	max-width: 1524px;
	margin: 0 auto;

	.text {
		&_bold {
			@extend %text_bold;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media only screen and (max-width: 1800px) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include adaptivity(tablet) {
			padding: 0;
			margin: 5px 0;
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 17px;
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		padding: 0 0 10px;
	}

	&__bottom-col {
		font: 17px/27px 'Arial';
		font-weight: 700;

		&_with-checkin-link {
			//position: relative;
		}

		&:nth-of-type(2) {
			font-size: 15px;
			font-weight: 400;
		}
	}
	&__lang-change {
		margin: 0 28px 0 12px;
	}

	&__checkin-link {
		@include fastTransition(color, 0.2s);
		font: 13px/16px Arial;
		position: absolute;
		bottom: -26px;
		right: 0;
		color: #ffffff;
		z-index: 2;
		padding: 5px 10px;
		background: $skewBg no-repeat;
		background-size: 100%;
		display: flex;
		align-items: center;

		&:hover {
			color: $brandBlueColor;
		}
	}

	&__link {
		@include fastTransition(color, 0.2s);
		position: relative;
		color: $brandBlueColor;
		font-family: $Arial;

		&::before {
			@include fastTransition(opacity, 0.2s);
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: #e6ebf1;
		}

		&:hover {
			color: $brandOrangeColor;

			&:before {
				opacity: 0;
			}
		}
	}

	&__links-list {
		display: flex;
	}

	&__links-list-item {
		list-style-type: none;
		//margin: 0 12px;

		&:first-of-type {
			margin-left: 0;
		}

		&_with-text-icon {
			margin-right: 30px;
			position: relative;

			@include adaptivity(laptop) {
				margin-right: 0;
			}

			&:before,
			&:after {
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
			}

			&:before {
				content: 'A';
				position: absolute;
				left: -28px;
				color: $brandOrangeColor;
				font: normal 10px/11px Arial;
				transform: translate(0, -30%);
			}

			&:after {
				content: 'A';
				position: absolute;
				left: -20px;
				color: #f68820;
				font: bold 15px/15px Arial;
			}
		}

		&_with-message-icon {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: -23px;
				top: 50%;
				transform: translate(0, -50%);
				background: $feedbackIcon no-repeat center;
				width: 21px;
				height: 14px;
				background-size: contain;
			}
		}
	}

	//.logo {
	//	.img {
	//		width: 300px;
	//		height: 100px;
	//		background: $auroraLogo left 24px no-repeat, $aeroflotLogo 151px 56px no-repeat;
	//	}
	//}

	&__search-form {
		height: 26px;
		display: flex;
		align-items: center;
	}

	&__search-input {
		height: 100%;
		width: 215px;
		border: 1px solid #e2e2e2;
		border-radius: 5px;
		box-shadow: none;
		outline: none;
		box-sizing: border-box;
		padding: 2px 10px;

		@include placeholder {
			color: $brandBlueColor;
		}
	}

	&__search-icon {
		height: 13px;
		width: 13px;
		background: $searchIcon;
		appearance: none;
		border: 0;
		position: relative;
		left: -20px;
		top: -1px;
	}

	&__phone-link {
		position: relative;
		margin-left: 39px;

		&:before {
			content: '';
			display: inline-block;
			margin: 0 5px 0 0;
			width: 13px;
			height: 18px;
			background: $icons 0 -12px no-repeat;
			position: relative;
			top: 2px;
		}
	}
}

.headerRight {
	position: initial;
	display: flex;
	justify-content: flex-end;
	width: calc(100% - 400px);
	align-items: center;

	@media (max-width: 1150px) {
		width: calc(100% - 200px);
	}

	&__col {
		&:nth-of-type(1) {
			padding-top: 8px;
		}

		&:nth-of-type(2) {
			position: relative;

			@include adaptivity(tablet) {
				top: 116px;
				left: -115px;
			}
		}
	}

	.selectedInfo {
		border-radius: 4px;
		&__i {
			> a {
				padding: 10px 25px 20px 55px;
				height: 45px;
			}
		}
	}

	.userNav {
		margin-top: 41px;

		&__i {
			a {
				margin-top: 0 !important;

				&:not(:nth-last-child) {
					font-weight: bold;
				}
			}
		}

		ul li {
			padding-left: 24px;
		}

		&__item {
			&_login {
				a {
					font-family: $Arial;
				}
				.icon {
					&_user {
						width: 10px;
						height: 10px;
						display: inline-block;
						background: $userIcon no-repeat 0 0;
						background-size: contain;

						&:before {
							display: none;
						}
					}
				}
			}
		}
	}

	.headerRight__col {
		display: flex;
		justify-content: flex-end;

		&.private__screen {
			position: absolute;
			top: 190px;

			@media only screen and (min-width: 1150px) and (max-width: 1200px) {
				top: 195px;
			}

			@media only screen and (min-width: 1100px) and (max-width: 1150px) {
				top: 250px;
			}

			@media only screen and (min-width: 1000px) and (max-width: 1100px) {
				top: 280px;
			}

			@media only screen and (max-width: 1000px) {
				top: 280px;
			}

			@include adaptivity(tablet) {
				margin: 10px 5px;
				top: 110px;
				left: -5px;
			}

			.selectedInfo__i.showHoverDrop_js a {
				@include adaptivity(tablet) {
					padding: 10px 40px;
					width: 100vw;
				}
			}
		}
	}
}

.userNav {
	ul {
		display: flex;
		align-items: center;
	}

	&__i {
		display: flex;
		justify-content: space-around;

		a {
			@extend %link;
			@extend %link_bold;
		}
	}

	a[fancybox='popupLogin'] {
		position: relative;

		//&:before {
		//	content: '';
		//	display: inline-block;
		//	position: absolute;
		//	width: 12px;
		//	height: 12px;
		//	left: -17px;
		//	top: 50%;
		//	transform: translate(0, -50%);
		//	background: $userIcon;
		//	//background-size: contain;
		//}
	}

	.login-link {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.ui-select-header {
	&.selectize-control {
		border: 1px solid #e2e2e2;
		border-radius: 5px;
		padding: 0 9px;
		max-height: 26px;

		&.single {
			.selectize-input:after {
				width: 20px;
				height: 10px;
				right: 0;
				top: 11px;
				border: none;
				background: $chevronDown no-repeat center;
			}
		}

		@include adaptivity(tablet) {
			width: 100%;
		}
	}

	.ui-select-placeholder.text-muted,
	.selectize-input .ui-select-match {
		color: #000000;
		font-weight: bold;
	}

	.selectize-input {
		padding: 0 10px 4px 0;
		margin-top: 0;
		overflow: visible;
		display: flex;
		align-items: center;
		font: bold 13px/26px Arial;

		&.focus {
			padding: 0 10px 4px 0;
		}
	}

	.ui-select-dropdown {
		left: -1px;
		top: 22px;
		padding-top: 0;
		box-sizing: content-box;
		box-shadow: none;
		border: 1px solid #e2e2e2;
		border-top: none;
		border-radius: 5px;
		width: 100%;
	}

	.ui-select-match {
		width: 100%;

		> span {
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.ui-select-choices-row {
		font-weight: bold;

		&.active {
			background: #ffffff;
		}

		&-inner {
			padding: 5px 9px;

			> div {
				display: flex;
				justify-content: space-between;
			}
		}

		&:hover {
			background: #ffffff !important;
			color: #000000;
		}
	}

	&-language {
		width: 110px;
	}
}

.icon_flag {
	width: 24px;
	margin-left: 4px;
	height: 19px;
	display: inline-block;
	background: $flags no-repeat 0 0;

	&-ru {
		background-position-x: -4293px;
	}

	&-en {
		background-position-x: -1773px;
	}
}

.icon_ticket {
	display: inline-block;
	margin-left: 10px;
	margin-right: 10px;
	width: 25px;
	height: 15px;
	background: $iconTicket;
}

.out {
	margin-bottom: -100px;
}

app-header .headerRight .userNav__i div a {
	&.header__phone-link {
		font: bold 20px/18px 'CaviarDreams';
		font-stretch: 100%;
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.75px;

		@include adaptivity(laptop) {
			font-size: 15px;
			margin-left: 15px;
		}

		@media (max-width: 960px) {
			display: none !important;
		}
	}
}

.userNav__i div a.header__phone-link {
	font: bold 20.65px 'CaviarDreams';
	letter-spacing: -0.75px;

	span {
		font-size: 17px;
	}
}

.header.header_small {
	padding: 9px 0 0;

	.logo {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;

		a {
			display: flex;
			align-items: flex-end;

			&.aeroflot-logo {
				margin: 0 25px 7px 0;

				@media only screen and (max-width: 1150px) {
					margin: 15px 0 0 20px;
				}

				@media only screen and (max-width: 1150px) {
					margin-top: 20px;
				}

				img {
					position: relative;
					right: 6px;

					@media only screen and (max-width: 1150px) {
						left: 0;
					}

					@include adaptivity(tablet) {
						width: 125px;
						margin-left: 30px;
					}
				}

				@include adaptivity(tablet) {
					margin: 20px 0 10px;
				}
			}

			&.aurora-logo {
				margin: 0;

				img {
					max-height: 70px;
					@include adaptivity(tablet) {
						width: 70px;
					}
				}
			}
		}

		@media only screen and (max-width: 1150px) {
			flex-wrap: wrap;
			max-width: 150px;
		}

		@include adaptivity(laptop) {
			max-width: 150px;
		}

		@include adaptivity(tablet) {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin: 0 0 0 10px;
			min-width: 350px;
		}
	}
}

.header__bottom {
	display: flex;
	position: relative;

	@include adaptivity(tablet) {
		padding: 0;
	}

	.header__bottom-col {
		ul.header__links-list li {
			margin-right: 23px;

			@media only screen and (max-width: 1200px) {
				margin-right: 10px;
				font-size: 17px;
			}

			@media only screen and (max-width: 1000px) {
				font-size: 15px;
			}

			@media only screen and (max-width: 800px) {
				font-size: 14px;
			}
		}

		@include adaptivity(tablet) {
			display: none;
		}
	}

	.header__bottom-col.header__bottom-col_with-checkin-link {
		display: flex;
		flex-direction: row;
		min-width: 300px;
		justify-content: flex-end;

		@include adaptivity(laptop) {
			display: flex;
			flex-wrap: wrap;
			max-width: 300px;
			justify-content: flex-end;
		}

		@include adaptivity(tablet) {
			width: 100%;
		}

		@media only screen and (max-width: 900px) {
			min-width: 200px;
		}

		.booking_link {
			height: fit-content;

			@include adaptivity(tablet) {
				right: 0;
				width: 100%;
			}
		}

		.header__checkin-link {
			width: 265px;
			justify-content: center;
			z-index: 0;

			@include adaptivity(laptop) {
				display: flex;
				position: absolute;
				bottom: 65px;
				right: 0;
			}

			@include adaptivity(tablet) {
				display: flex;
				position: absolute;
				bottom: -5px;
				right: 0;
			}
		}

		.header__links-list {
			flex-wrap: wrap;
			justify-content: flex-end;

			@include adaptivity(laptop) {
				max-width: 300px;
			}

			@include adaptivity(tablet) {
				display: none;
			}

			.header__links-list-item {
				margin: 0 12px;

				&_with-text-icon {
					margin-right: 30px;

					@include adaptivity(laptop) {
						margin-right: 0;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}

			@media (max-width: 960px) {
				max-width: 200px;

				.header__links-list-item_with-message-icon {
					margin: 0;
				}
			}
		}
	}
}

.header__search-form {
	margin-right: 5px;
	@include adaptivity(laptop) {
		width: 170px;
	}
}

.header_box_mobile {
	.burger {
		display: none;

		@include adaptivity(tablet) {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 26px 18px;
			height: 70px;
			top: 0;
			right: 0;
			//z-index: 8;
			background: #ffa42e;
		}
	}

	div.menu.active {
		display: none;

		@include adaptivity(tablet) {
			background: #ffa42e;
			padding: 20px;
			position: absolute;
			z-index: 200;
			width: 100vw;
			right: 0;
			top: 70px;
			color: white;
			font-size: 20px;
		}

		.languages {
			@include adaptivity(tablet) {
				position: static;
				float: none;
				height: auto;
				width: 100%;
				margin: 0;
				padding: 15px 0;
			}

			.ui-select-header.selectize-control {
				@include adaptivity(tablet) {
					background: #f7a42f;
					padding: 0;
				}

				.ui-select-match {
					@include adaptivity(tablet) {
						color: #ffffff;
					}
				}
			}

			.ui-select-header .ui-select-placeholder.text-muted {
				@include adaptivity(tablet) {
					color: #ffffff;
				}
			}
		}

		.b_menu .page ul li {
			@include adaptivity(tablet) {
				list-style-type: none;
				padding: 20px 0;
				border-top: 1px solid white;

				.nav-link {
					color: white;
				}

				&:hover {
					a {
						color: $brandBlueColor;
						cursor: pointer;
						transition: 0.5s;
					}
				}
			}
		}

		.cabinet,
		.request,
		.search {
			@include adaptivity(tablet) {
				padding: 20px 0;
				border-top: 1px solid white;
				font-size: 14px;

				a {
					color: white;
				}

				&:hover {
					a {
						color: $brandBlueColor;
						cursor: pointer;
						transition: 0.5s;
					}
				}
			}
		}

		.search {
			@include adaptivity(tablet) {
				display: block;
				position: relative;
				float: none;
				width: 100%;
				margin: 0;
				padding: 0;
			}

			input {
				@include adaptivity(tablet) {
					padding: 18px 0;
					width: 100%;
					font: bold 13px/20px Arial;
					color: #ffffff;
					background: none;
					-webkit-border-radius: 5px;
					border-radius: 0;
					-webkit-box-shadow: inset 0 0 0 50px #f7a42f;
					-webkit-text-fill-color: #fff;
					border: 0;
				}
			}

			button {
				@include adaptivity(tablet) {
					position: absolute;
					width: 13px;
					height: 13px;
					margin: auto;
					top: 0;
					bottom: 0;
					right: 0;
					left: auto;
					border: 0;
					cursor: pointer;
					background: url('https://www.flyaurora.ru//images/search_b.png') 0 0 no-repeat;
					text-indent: -9999px;
				}
			}
		}
	}

	input[type='checkbox'] {
		@include adaptivity(tablet) {
			padding: 20px;
			width: 10px;
			height: 10px;
			position: absolute;
			cursor: pointer;
			display: block;

			&:checked ~ div.menu {
				display: block;
			}

			&:not(:checked) ~ div.menu {
				display: none;
			}
		}
	}
}

.searchSteps,
.regSteps {
	background: $brandBlueColor;
}

.headerLeft {
	width: 100%;
	max-width: 320px;

	@media only screen and (max-width: 1150px) {
		max-width: 200px;
	}

	//@media only screen and (max-width: 1000px) {
	//	max-width: 200px;
	//}

	@include adaptivity(tablet) {
		max-width: 320px;
	}
}

.header.header_small .headerRight .userNav {
	margin: 32px 0 10px;

	.login-link {
		svg {
			width: 16px;
			height: 16px;
		}

		.popup_js {
			padding-left: 7px;
			font-weight: 700;
		}
	}

	.languages__select {
		margin: 0 30px 0 12px;
	}

	@include adaptivity(laptop) {
		display: flex;
		align-items: center;
	}

	@include adaptivity(tablet) {
		display: none;
	}
}

.ui-select-header.selectize-control {
	@include adaptivity(tablet) {
		border: none;
	}
}

app-header .headerRight .userNav__i div a.header__phone-link {
	@media (max-width: 1200px) {
		font: bold 18px/18px 'CaviarDreams';
	}

	@media only screen and (min-width: 1100px) and (max-width: 1200px) {
		margin-left: 10px;
	}
}

app-header .header__search-input {
	@media (max-width: 1300px) {
		width: 160px;
		transition: 0.5s;
	}
}

.headerAlias {
	@include adaptivity(laptop) {
		display: none;
	}
}

.ui-select-header .selectize-input .ui-select-match {
	font: bold 13px/26px Arial;
}

.content .wrap.wrap_pt32 {
	@include adaptivity(tablet) {
		padding-top: 55px;
	}
}

/* ==== END 44943 ==== */

.btn.btn_search,
.btn.is-yellow {
	background: $brandOrangeColor;

	&:hover {
		background: $brandOrangeColor;
	}
}

.btn.is-blue {
	background: $brandBlueColor;

	&:hover {
		background: $brandBlueColor;
	}
}

.common-warning__header {
	color: $brandBlueColor;
}

.common-warning__inner {
	border-color: $brandBlueColor;
}

.passengerInfo {
	.customCheckbox {
		label {
			padding-left: 0;
			pointer-events: none;
		}

		input {
			display: none;
		}

		span {
			display: none;
		}
	}
}

select.ui-select-match {
	border: 1px solid #e2e2e2;
	border-radius: 5px;
	padding: 0 9px;
	max-height: 26px;
	width: 110px;
	height: 26px;
	color: black;
	background: white;
}

header.page .right .right_box .phone {
	margin: 0 0 0 39px;
}
.header_box_btm {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 22px 0 0;
	-webkit-box-align: baseline;
	-webkit-align-items: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}

header.page .header_box_btm .request {
	padding: 0;
	line-height: 27px;
	margin: -7px 0 0;
}

header.page .header_box .phone .phone-number:last-child {
	margin-bottom: 0;
}
header.page .phone .phone-number {
	color: #172d55;
}
header.page .header_box .phone .phone-number .cx {
	display: none;
}

.custom-select {
	position: relative;
	font-family: Arial;
	border: 1px solid #e2e2e2;
	border-radius: 5px;

	@include adaptivity(tablet) {
		width: 100% !important;
		background: #f7a42f;
		height: 100% !important;
		border: none;
	}

	.select-selected {
		@include adaptivity(tablet) {
			font: bold 13px/26px Arial;
			height: auto;
			border: 1px solid #e2e2e200;
			color: #fff !important;
			padding: 0;
			text-align: left;
			background: #f7a42f;
		}
	}
}

.custom-select select {
	display: none; /*hide original SELECT element: */
}

.select-selected {
	background-color: #fff;
	font-weight: 600;
}

/* Style the arrow inside the select element: */
.select-selected:after {
	position: absolute;
	content: '';
	top: 9px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #ffa32e transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
	border-color: transparent transparent #ffa32e transparent;
	top: 4px;
}

/* style the items (options), including the selected item: */
.select-items a,
.select-selected {
	color: #000;
	padding: 4px 16px;
	/* border: 1px solid #000;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent; */
	cursor: pointer;
}

/* Style items (options): */
.select-items {
	position: absolute;
	background-color: #fff;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
	border: 1px solid #e2e2e2;

	@include adaptivity(tablet) {
		display: flex;
		flex-direction: column;
	}
}

/* Hide the items when the select box is closed: */
.select-hide {
	display: none;
}

.select-items a:hover,
.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

.headerRight .languages__select .select-selected:before,
.headerRight .languages__select .select-items a:nth-child(1):before,
.burger .languages__select > div > div.select-items.select-hide a:nth-child(1) {
	content: '';
	position: absolute;
	background-repeat: no-repeat;
	background-image: url('https://www.flyaurora.ru/images/flags.png');
	width: 24px;
	height: 17px;
	background-position: -1773px 0px;
	top: 7px;
	right: 34px;
}
.headerRight .languages__select .select-selected {
	&.ru:before {
		background-position: -4293px 0px;
		top: 3px;
	}

	&.en:before {
		background-position: -1773px 0px;
	}
}

.headerRight .languages__select .select-items a:nth-child(2):before {
	content: '';
	position: absolute;
	background-repeat: no-repeat;
	background-image: url('https://www.flyaurora.ru/images/flags.png');
	width: 24px;
	height: 17px;
	background-position: -4293px 0px;
	top: 27px;
	right: 34px;
}

.header_box_mobile .languages__select.select-selected.eng:before {
	content: '';
	position: absolute;
	background-repeat: no-repeat;
	background-image: url('https://www.flyaurora.ru/images/flags.png');
	width: 24px;
	height: 17px;
	background-position: -4293px 0px;
	top: 27px;
	right: 34px;
}

#oxyCheckinApp {
	height: 70vh;
}

.headerRight .languages__select .select-items a {
	width: 70%;
}

.header_box_mobile {
	.burger {
		display: none;

		@include adaptivity(tablet) {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 26px 18px;
			height: 70px;
			top: 0;
			right: 0;
			//z-index: 8;
			background: #ffa42e;
		}
	}

	div.menu.active {
		display: none;

		@include adaptivity(tablet) {
			background: #ffa42e;
			padding: 20px;
			position: absolute;
			z-index: 200;
			width: 100vw;
			right: 0;
			top: 70px;
			color: white;
			font-size: 20px;
		}

		.languages {
			@include adaptivity(tablet) {
				position: static;
				float: none;
				height: auto;
				width: 100%;
				margin: 0;
				padding: 15px 0;
			}

			.ui-select-header.selectize-control {
				@include adaptivity(tablet) {
					background: #f7a42f;
					padding: 0;
				}

				.ui-select-match {
					@include adaptivity(tablet) {
						color: #ffffff;
					}
				}
			}

			.ui-select-header .ui-select-placeholder.text-muted {
				@include adaptivity(tablet) {
					color: #ffffff;
				}
			}
		}

		.b_menu .page ul li {
			@include adaptivity(tablet) {
				list-style-type: none;
				padding: 20px 0;
				border-top: 1px solid white;

				.nav-link {
					color: white;
				}

				&:hover {
					a {
						color: $brandBlueColor;
						cursor: pointer;
						transition: 0.5s;
					}
				}
			}
		}

		.cabinet,
		.request,
		.search {
			@include adaptivity(tablet) {
				padding: 20px 0;
				border-top: 1px solid white;
				font-size: 14px;

				a {
					color: white;
				}

				&:hover {
					a {
						color: $brandBlueColor;
						cursor: pointer;
						transition: 0.5s;
					}
				}
			}
		}

		.search {
			@include adaptivity(tablet) {
				display: block;
				position: relative;
				float: none;
				width: 100%;
				margin: 0;
				padding: 0;
			}

			input {
				@include adaptivity(tablet) {
					padding: 18px 0;
					width: 100%;
					font: bold 13px/20px Arial;
					color: #ffffff;
					background: none;
					-webkit-border-radius: 5px;
					border-radius: 0;
					-webkit-box-shadow: inset 0 0 0 50px #f7a42f;
					-webkit-text-fill-color: #fff;
					border: 0;
				}
			}

			button {
				@include adaptivity(tablet) {
					position: absolute;
					width: 13px;
					height: 13px;
					margin: auto;
					top: 0;
					bottom: 0;
					right: 0;
					left: auto;
					border: 0;
					cursor: pointer;
					background: url('https://www.flyaurora.ru//images/search_b.png') 0 0 no-repeat;
					text-indent: -9999px;
				}
			}
		}
	}

	input[type='checkbox'] {
		@include adaptivity(tablet) {
			padding: 20px;
			width: 10px;
			height: 10px;
			position: absolute;
			cursor: pointer;

			&:checked ~ div.menu {
				display: block;
			}

			&:not(:checked) ~ div.menu {
				display: none;
			}
		}
	}
}

@media (max-width: 767px) {
	header.page .wrapper .header_box .burger {
		display: block;
		padding: 26px 18px;
		height: 18px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 8;
		background: #ffa42e;
		cursor: pointer;
	}
	header.page .wrapper .header_box .burger span {
		display: block;
		margin-top: 7px;
		-webkit-border-radius: 1px;
		border-radius: 1px;
		width: 24px;
		height: 3px;
		background: #172d56;
		position: relative;
	}
	header.page .wrapper .header_box .burger span:before,
	header.page .wrapper .header_box .burger span:after {
		display: block;
		content: '';
		-webkit-border-radius: 1px;
		border-radius: 1px;
		width: 24px;
		height: 3px;
		background: #172d56;
		position: absolute;
		left: 0;
		right: 0;
	}
	header.page .wrapper .header_box .burger span:before {
		top: -7px;
	}
	header.page .wrapper .header_box .burger span:after {
		bottom: -7px;
	}
	header.page nav .menu-icon {
		display: none;
	}

	header.page .header_box .right,
	.header_box_btm {
		display: none !important;
	}
	header.page .brg_b nav ul {
		display: block;
		position: static;
		background: #ffa42e;
	}
	header.page .brg_b nav ul li {
		border-top: 1px solid #fff;
	}
	header.page .nav-link:hover,
	header.page a:hover {
		color: #172d56 !important;
	}
	header.page .brg_b nav ul li.active .nav-link:hover,
	header.page .brg_b nav ul li.active a:hover {
		color: #fff !important;
	}
	header.page .brg_b .languages {
		position: static;
		float: none;
		height: auto;
		width: -webkit-calc(100% - 30px);
		width: calc(100% - 30px);
		margin: 0 auto;
		padding: 15px 0;
	}
	.languages_slc .chosen-container.chosen-container-active {
		background: #f7a42f;
	}
	.languages_slc .chosen-container-single .chosen-single {
		font: bold 13px/26px Arial;
		height: auto;
		border: 1px solid #e2e2e200;
		color: #fff !important;
		padding: 0;
		text-align: left;
	}
	.languages_slc .chosen-container .chosen-drop {
		border: 1px solid rgba(226, 226, 226, 0);
		background: #f7a42f;
	}
	.languages_slc .chosen-container {
		background: #f7a42f;
	}
	.languages_slc .chosen-container .chosen-results li {
		color: #fff;
		border-bottom: 1px solid rgba(255, 255, 255, 0.4196078431372549);
		font: bold 13px/38px Arial;
	}
	.languages_slc .chosen-container .chosen-results li:hover {
		color: #172d56;
	}
	.chosen-container .chosen-results li.result-selected {
		color: #fff;
	}

	body header.page .wrapper .brg_b {
		background: #ffa42e;
		display: none;
		padding: 30px 0 5px;
		position: absolute;
		z-index: 200;
		width: 100%;
		top: 70px;
	}
	body header.page .wrapper .brg_b.active {
		display: block;
	}
	.header_box .left_box {
		max-width: -webkit-calc(100% - 80px);
		max-width: calc(100% - 80px);
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
	body.home header.page .logo,
	header.page .logo {
		width: auto;
		float: none;
		margin: 0 25px 0 0;
	}
	header.page .wrapper .header_box {
		padding: 8px 15px;
	}
	header.page .brg_b .cabinet {
		width: -webkit-calc(100% - 30px);
		width: calc(100% - 30px);
		margin: 0 auto;
		float: none;
		border-top: 1px solid #fff;
		padding: 14px 0;
	}
	header.page .brg_b .cabinet a {
		color: #fff;
		border: 0;
		font: bold 13px/26px Arial;
	}
	header.page .brg_b .cabinet a:before {
		display: none;
	}
	header.page .brg_b .request {
		float: none;
		width: -webkit-calc(100% - 30px);
		width: calc(100% - 30px);
		margin: 0 auto;
		padding: 0 0;
		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;
		padding: 14px 0 13px;
	}
	header.page .brg_b .request a {
		color: #fff;
		border: 0;
		font: bold 13px/26px Arial;
	}
	header.page .brg_b .search {
		float: none;
		width: -webkit-calc(100% - 30px);
		width: calc(100% - 30px);
		margin: 0 auto;
		padding-right: 0;
		display: block;
		position: relative;
	}
	header.page .brg_b div.search input {
		border: 1px solid #5e6d8a00;
		padding: 18px 0;
		width: 100%;
		font: bold 13px/20px Arial;
		color: #ffffff;
		background: none;
		-webkit-border-radius: 5px;
		border-radius: 0;
	}
	header.page .brg_b div.search input::-webkit-input-placeholder {
		color: #ffffff;
	}
	header.page .brg_b div.search input::-moz-placeholder {
		color: #ffffff;
	}
	header.page .brg_b div.search input:-ms-input-placeholder {
		color: #ffffff;
	}
	header.page .brg_b div.search input:-moz-placeholder {
		color: #ffffff;
	}
	header.page .brg_b div.search input {
		-webkit-box-shadow: inset 0 0 0 50px #f7a42f;
		-webkit-text-fill-color: #fff;
		border: 0;
	}
	header.page .brg_b div.search button {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		left: auto;
	}
	header.page {
		padding: 0 0 25px;
	}
}

@media (max-width: 420px) {
	body.home header.page .logo img {
		width: 70px;
	}
	header.page .wrapper .header_box .burger {
		height: 16px;
	}
	body header.page .wrapper .brg_b {
		top: 67px;
	}
}

.burger .languages__select {
	@include adaptivity(tablet) {
		position: static;
		float: none;
		height: auto;
		width: -webkit-calc(100% - 30px);
		width: calc(100% - 30px);
		padding: 15px 0;
	}
}

footer .phone-number {
	@include adaptivity(tablet) {
		font-size: 17px !important;
	}
}
