@import '../common/mixins';
@import './variables';
/**
*   @section footer.page
*/

footer.page .socials.mobile {
	display: none;

	@include adaptivity(tablet) {
		display: block;
	}
}

.not_mobile {
	@include adaptivity(tablet) {
		display: none;
	}
}
footer.page {
	background: #fff;
	position: relative;
	//z-index: 3;
	overflow: hidden;
	width: 100%;
	font-family: 'Arial', sans-serif;
}
footer.page nav {
	padding: 87px 120px 80px 0;
	font-size: 13px;
	line-height: 1;
}
footer.page nav a {
	color: #000000;
	@include fastTransition(color, 0.2s);
	&:hover {
		color: $brandOrangeColor;
	}
}
footer.page nav > ul > li {
	float: left;
	padding-left: 120px;
	width: 25%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
footer.page nav .title {
	margin-bottom: 14px;
	border-bottom: 1px solid #c9c9c9;
	padding-bottom: 8px;
	font-weight: bold;
	font-size: 15.5px;
	text-transform: uppercase;
	white-space: nowrap;
}
footer.page nav .title a {
	color: #000;
	position: relative;
	@include fastTransition(color, 0.2s);
	&:hover {
		color: $brandOrangeColor;
	}
}
footer.page nav .title a:after {
	display: block;
	content: '';
	margin-top: -11px;
	margin-top: 0 !important;
	width: 22px;
	height: 22px;
	background: $footerNav 0 0 no-repeat;
	position: absolute;
	top: 50%;
	top: auto !important;
	bottom: 0;
	right: 0;
}
footer.page nav li.about .title a {
	padding-right: 33px;
}
footer.page nav li.about .title a:after {
	background-position: 0 0;
}
footer.page nav li.buy .title a {
	padding-right: 39px;
}
footer.page nav li.buy .title a:after {
	margin-top: -10px;
	width: 28px;
	height: 19px;
	background-position: 0 -22px;
}
footer.page nav li.passengers .title a {
	padding-right: 35px;
}
footer.page nav li.passengers .title a:after {
	width: 24px;
	background-position: 0 -41px;
}
footer.page nav li.services .title a {
	padding-right: 33px;
}
footer.page nav li.services .title a:after {
	background-position: 0 -63px;
}
footer.page nav li.deals {
	margin: -38px 0 0 50%;
	clear: both;
}
footer.page nav li.deals .title a {
	padding-right: 25px;
}
footer.page nav li.deals .title a:after {
	margin-top: -10px;
	width: 14px;
	height: 20px;
	background-position: 0 -85px;
}

footer.page nav li.agents .title a {
	&:before {
		background: $footerNav;
		content: '';
	}
}
footer.page nav li.agents .title a:after {
	content: '';
	position: absolute;
	top: 2px;
	right: -9px;
	bottom: 0;
	margin: auto;
	background: $footerNav_agents no-repeat 0 0;
	width: 31px;
	height: 24px;
	display: block;
}
footer.page nav li li {
	border-top: 1px solid #e2e2e2;
	padding: 12px 0;
	list-style-type: none;
}
footer.page nav li li:first-child {
	border: 0;
}
footer.page .bottom {
	font-size: 13px;
	color: #5d6c89;
	background: #172d56;
	position: relative;
}
footer.page .line {
	margin: 0;
	height: 121px;
	background: #fff $footerLine 0 0 repeat-x;
}
footer.page .line:before {
	display: block;
	content: '';
	width: 183px;
	height: 250px;
	background: #fff $footerLines -49px 0 no-repeat;
	position: absolute;
	top: 0;
	left: 0;

	@include adaptivity(tablet) {
		left: -143px;
	}
}
footer.page .line:after {
	display: block;
	content: '';
	width: 101px;
	height: 206px;
	background: #fff $footerLines -232px 0 no-repeat;
	position: absolute;
	top: -85px;
	right: 0;

	@include adaptivity(tablet) {
		width: 40px;
	}
}
footer.page .line .left {
	width: 49px;
	background: #fff $footerLines 0 0 repeat-y;
	position: absolute;
	top: 250px;
	left: 0;
	bottom: 0;

	@include adaptivity(tablet) {
		display: none;
	}
}
footer.page .wrap {
	margin: 0 auto;
	padding: 12px 0;
	max-width: 488px;
	text-align: center;
}
footer.page .call {
	margin: 28px 0 18px;
	font-size: 13px;
	color: #8b96ab;
}
footer.page .call span {
	display: block;
	margin-top: 4px;
	letter-spacing: -0.75px;
	color: #fff;
}
footer.page .call span a {
	color: #fff;
	font: bold 22px/22px 'CaviarDreams';
}
footer.page .call span p {
	color: #a7acb7;
	text-align: center;
	font: normal 13px/13px Arial;
	margin: 0 0 19px;
}
footer.page nav li .title a {
	padding-right: 32px;
}

footer.page .copyrights {
	margin: 18px 0 0;
	border-top: 1px solid #273c62;
	padding-top: 18px;
	font-weight: bold;
	text-transform: uppercase;
}
footer.page .developer {
}
footer.page .developer a {
	font-weight: bold;
	font-size: 9px;
	text-transform: uppercase;
	color: #5d6c89;
}
footer.page .developer a span {
	text-decoration: underline;
	color: #fff;
}
footer.page .developer a:hover {
	color: #fff !important;
}
/* .socials */
footer.page .socials {
	margin: 0 0 28px;
}
footer.page .socials a {
	margin-left: 110px;
}
footer.page .socials a:first-child {
	margin-left: 0;
}
footer.page .socials a:before,
footer.page .socials a:after {
	display: block !important;
	margin-left: -15px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	top: -52px;
	left: 50%;
}
footer.page .socials a:before {
	background: #ffa42e;
}
footer.page .socials a:after {
	background: #fff;
}
footer.page .socials a i {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
}
footer.page .socials a i:before,
footer.page .socials a i:after {
	display: block;
	content: '';
	width: 100%;
	height: 100%;
	background: $socials 0 0 no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 0;
	-webkit-transition: opacity 0.25s linear;
	-o-transition: opacity 0.25s linear;
	transition: opacity 0.25s linear;
}
footer.page .socials a:before,
footer.page .socials a i:before {
	z-index: 2;
	opacity: 1;
}
footer.page .socials a:hover:before,
footer.page .socials a:hover i:before {
	opacity: 0;
}
footer.page .socials a:hover:after,
footer.page .socials a:hover i:after {
	opacity: 1;
}
footer.page .socials a.twitter i:before {
	background-position: 0 0;
}
footer.page .socials a.twitter i:after {
	background-position: 0 -52px;
}
footer.page .socials a.facebook i:before {
	background-position: -30px 0;
}
footer.page .socials a.facebook i:after {
	background-position: -30px -52px;
}
footer.page .socials a.vk i:before {
	background-position: -60px 0;
}
footer.page .socials a.vk i:after {
	background-position: -60px -52px;
}
footer.page .socials a.instagram i:before {
	background-position: -90px 0;
}
footer.page .socials a.instagram i:after {
	background-position: -90px -52px;
}

/* Ñ„Ð¾Ñ‚ÐºÐ° ÑÐ²ÐµÑ€Ñ…Ñƒ ÑÐ¿Ñ€Ð°Ð²Ð° Ð½Ð° ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ðµ */

div.page-right-top-image {
	float: right;
	text-align: right;
	margin-right: -45px;
}

div.page-right-top-image img {
	width: 400px;
	-o-object-fit: cover;
	object-fit: cover;
	/*margin-top: -50px;*/
	/*margin-right: -50px;*/
	-webkit-border-radius: 0 0 0 60px;
	border-radius: 0 0 0 60px;
	margin: 0;
}

header.page .phone-number:before {
	display: inline-block;
	content: '';
	margin: 0 5px 0 0;
	width: 13px;
	height: 18px;
	background: url(/images/icons.png) 0 -12px no-repeat;
	position: relative;
	top: 2px;
}

header.page .phone {
	text-align: right;
}

header.page .phone:before {
	display: none !important;
}

header.page .phone .phone-number {
	margin-bottom: 0;
}

header.page .phone .phone-number:last-child {
	margin-bottom: -22px;
}

footer.page .wrap {
	max-width: 590px !important;
}

article.home .slider .pager {
	z-index: 100;
}

section.deals .wrap .date {
	visibility: hidden;
}

.text .lighttable td {
	padding: 5px;
	background: none !important;
	border: 1px solid #afafaf;
}

.text ol li {
	margin-bottom: 5px;
}

body.home .page-bg {
	/*background-image:url(/upload/iblock/fa0/fa05d71db4fbe1d7a2c3d3556638e8a8.jpg);*/
	top: 139px;
}

@media (max-width: 1150px) {
	body.home .page-bg {
		/*background-image:url(/upload/iblock/fa0/fa05d71db4fbe1d7a2c3d3556638e8a8.jpg);*/
		top: 210px;
	}
}

@media only screen and (max-width: 767px) {
	.call .phone-number {
		font-size: 60%;
	}
}

li.agents ul {
	display: block;
}

footer.page nav > ul > li {
	padding-left: 95px;
	width: 20%;
}

footer.page nav li.deals {
	margin: 0px 0 0 0;
	clear: none;
}

li.agents {
	clear: both;
	margin: -376px 0 0 80%;
}

@media only screen and (max-width: 1200px) {
	footer.page nav > ul > li {
		padding-left: 30px;
		width: 20%;
	}

	footer.page nav .title a:after {
		display: none;
	}
}

@media only screen and (max-width: 1085px) {
	footer.page nav .title {
		font-size: 13.5px;
	}
}

@media only screen and (max-width: 975px) {
	footer.page nav > ul > li {
		width: 50%;
	}
}

/* Ñ€Ð°Ð·Ð¼ÐµÑ€Ñ‹ Ð°Ð²Ñ‚Ð¾ÐºÐ¾Ð¼Ð¿Ð»Ð¸Ñ‚Ð¾Ð² */
.ui-autocomplete .ui-menu-item {
	padding: 8px 0 8px 16px !important;
	font-weight: normal;
}

.ui-autocomplete .ui-menu-item.ui-state-focus {
	font-weight: bold;
}

@media only screen and (max-width: 767px) {
	.ui-autocomplete .ui-menu-item {
		padding: 16px 0 16px 16px !important;
	}
}

/********/
/**
article.home .links a {
    color: #B0A48D !important;
}

article.home .links a:before {
    background: #B0A48D;
}

article.home .slider .pager a.active {
    border-color: #B0A48D;
    background: #B0A48D;
}

article.home .widget button span {
    background: #B0A48D;
}

article.home .widget .tabs .tickets.active a:before {
    filter: grayscale(80%);
}

article.home .widget .tabs .active a:after {
    background: #B0A48D;
}

.page-bg .images > div {
    filter: grayscale(80%);
}

img {
    filter: grayscale(80%);
}

section.last-news article .date {
    color: #B0A48D;
}

section.last-news article .more span {
    filter: grayscale(80%);
}

footer.page .socials a:before {
    background: #B0A48D;
}

footer.page .socials a i:before, footer.page .socials a i:after {
    filter: grayscale(80%);
}

footer.page nav .title a:after {
    filter: grayscale(80%);
}

a:hover, .left-link:hover {
    color: #B0A48D !important;
}

header.page .phone-number:before {
    filter: grayscale(80%);
}

header.page .request a:before {
    filter: grayscale(80%);
}

article.home .slider .pager a:hover, article.home .slider .pager a.active {
    border-color: #B0A48D;
    background: #B0A48D;
}
**/
footer.page nav {
	padding: 87px 70px 80px;
}

footer.page nav > ul {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

footer.page nav > ul > li {
	width: 14%;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

@media (max-width: 1550px) {
	footer.page nav {
		padding: 67px 35px 60px;
	}
}

@media (max-width: 1400px) {
	footer.page nav > ul {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	footer.page nav > ul > li {
		width: 30%;
		margin: 0 0 45px;
	}

	footer.page nav > ul > li.about ul,
	footer.page nav > ul > li.services ul,
	footer.page nav > ul > li.agents ul {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	footer.page nav > ul > li.about ul li {
		width: 45%;
	}

	footer.page nav > ul > li.services ul li {
		width: 100%;
	}

	footer.page nav > ul > li.agents {
		width: 100%;
	}

	footer.page nav > ul > li.agents ul li {
		width: 45%;
	}

	footer.page nav li li {
		border-top: 0;
		border-bottom: 1px solid #e2e2e2;
	}

	footer.page nav li li:first-child {
		border: 0;
		border-bottom: 1px solid #e2e2e2;
	}

	footer.page nav > ul > li.deals {
		width: 100%;
		-webkit-box-ordinal-group: 11;
		-webkit-order: 10;
		-ms-flex-order: 10;
		order: 10;
	}
}

@media (max-width: 550px) {
	footer.page nav {
		padding: 47px 15px 40px;
	}
}

@media (max-width: 370px) {
	footer.page nav > ul > li.about ul li,
	footer.page nav > ul > li.services ul li {
		width: 100%;
	}

	footer.page nav > ul > li.buy {
		width: 100%;
	}

	footer.page nav > ul > li.passengers {
		width: 100%;
	}

	footer.page nav > ul > li.agents {
		width: 100%;
	}

	footer.page nav > ul > li.agents ul li {
		width: 100%;
	}
}
/* Ð´Ð»Ñ Ð²ÑÐ¿Ð»Ñ‹Ð²Ð°ÑŽÑ‰ÐµÐ³Ð¾ Ð¾ÐºÐ½Ð° */

.modal {
	width: 100%;
	max-width: 1070px;
	padding: 20px 0 60px;
}

.modal h2 {
	position: relative;
	padding: 50px 10px;
	margin: 0;
	font-family: 'CaviarDreams', Arial, sans-serif;
	font-size: 28px;
	text-transform: uppercase;
	line-height: 38px;
	color: #172d56;
	text-align: center;
	background-color: #ffffff;
}

.modal h2::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: calc(50% - 100px);
	display: block;
	width: 200px;
	height: 2px;
	background-color: #ffa42e;
}

.modal p {
	font-family: Arial, sans-serif;
	max-width: 730px;
	padding: 0 40px;
	font-size: 18px;
	line-height: 36px;
	color: #000000;
	text-align: center;
}

.modal p:first-of-type {
	margin: 30px auto 0;
}

.modal p:last-child {
	margin: 50px auto 0;
}

.modal a {
	text-decoration: none;
	font-weight: bold;
	color: #000000;
}

.modal a:hover {
	text-decoration: underline;
}

.modal .modal-btn {
	border: 1px solid #172d56;
	border-radius: 5px;
	padding: 5px;
	font-weight: normal;
	color: #172d56;
}

.modal .modal-btn:hover {
	text-decoration: none;
	border: 1px solid #ffa42e;
}
.fancybox-close {
	right: 70px;
}

.home li.vzc {
	display: none;
}

// socials
.socials {
	font-size: 0;
}

.socials a {
	display: inline-block;
	width: 30px;
	height: 26px;
	background: transparent;
	position: relative;
}

.socials a:before,
.socials a:after {
	display: block;
	content: '';
	width: 100%;
	height: 100%;
	background: url(../images/socials.png) 0 0 no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 0;
	-webkit-transition: opacity 0.25s linear;
	-o-transition: opacity 0.25s linear;
	transition: opacity 0.25s linear;
}

.socials a:before {
	z-index: 2;
	opacity: 1;
}

.socials a:hover:before {
	opacity: 0;
}

.socials a:hover:after {
	opacity: 1;
}

.socials a.twitter:before {
	background-position: 0 0;
}

.socials a.twitter:after {
	background-position: 0 -26px;
}

.socials a.facebook:before {
	background-position: -30px 0;
}

.socials a.facebook:after {
	background-position: -30px -26px;
}

.socials a.vk:before {
	background-position: -60px 0;
}

.socials a.vk:after {
	background-position: -60px -26px;
}

.socials a.instagram:before {
	background-position: -90px 0;
}

.socials a.instagram:after {
	background-position: -90px -26px;
}

@include adaptivity(tablet) {
	footer.page .socials {
		margin: 50px 0;
	}

	footer.page .socials a {
		margin-left: 50px;
	}

	footer.page .socials a::before,
	footer.page .socials a::after {
		margin: -25px 0 0 -25px;
		width: 50px;
		height: 50px;
		top: 50%;
		left: 50%;
	}

	footer.page .socials a i {
		z-index: 3;
	}

	footer.page .socials a i::before {
		opacity: 0;
	}

	footer.page .socials a i::after {
		opacity: 1;
	}

	footer.page .socials a:hover i:before {
		opacity: 1;
	}

	footer.page .socials a:hover i:after {
		opacity: 0;
	}
}
