/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;
}

@function em($wanted, $base: 16) {
	@return strip-unit($wanted) / strip-unit($base) * 1em;
}

@function rem($wanted, $base: 16) {
	@return strip-unit($wanted) / strip-unit($base) * 1rem;
}

@function percent($wanted, $context) {
	@return strip-unit($wanted) / strip-unit($context) * 100 * 1%;
}

@function get-deep($map, $keys...) {
	// https://css-tricks.com/snippets/sass/deep-getset-maps/
	@each $key in $keys {
		$map: map-get($map, $key);
	}
	@return $map;
}

@function merge($parent-map, $child-map) {
	// https://medium.com/@pentzzsolt/a-non-destructive-map-merge-function-for-sass-f91637f87b2e
	$result: $parent-map;
	@each $key, $value in $child-map {
		@if (not map-has-key($result, $key)) or
			(type-of(map-get($result, $key)) != type-of($value)) or
			(not (type-of(map-get($result, $key)) == map and type-of($value) == map))
		{
			$result: map-merge(
				$result,
				(
					$key: $value
				)
			);
		} @else {
			$result: map-merge(
				$result,
				(
					$key: merge(map-get($result, $key), $value)
				)
			);
		}
	}
	@return $result;
}

@mixin fastTransition($props, $duration: 0.15s, $timing: ease-in-out) {
	transition-property: join((), $props, comma);
	transition-duration: $duration;
	transition-timing-function: $timing;
}

@mixin clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

@mixin adaptivity($media) {
	$screen-size-laptop-large: 1440px;
	$screen-size-laptop: 1100px;
	$screen-size-tablet: 768px;
	$screen-size-phone: 515px;
	@if $media == desktop {
		@media only screen and (min-width: $screen-size-laptop-large) {
			@content;
		}
	}
	@if $media == laptop-large {
		@media only screen and (max-width: $screen-size-laptop-large) {
			@content;
		}
	} @else if $media == laptop {
		@media only screen and (max-width: $screen-size-laptop) {
			@content;
		}
	} @else if $media == only-laptop {
		@media only screen and (min-width: $screen-size-tablet) and (max-width: $screen-size-laptop) {
			@content;
		}
	} @else if $media == tablet {
		@media only screen and (max-width: $screen-size-tablet) {
			@content;
		}
	} @else if $media == phone {
		@media only screen and (max-width: $screen-size-phone) {
			@content;
		}
	}
}

@mixin changeHeight {
	/* ==== changeHeightMixin in common/_mixins.sass ==== */
	.flightTable .contentRow td:not(.firstCol) {
		height: 10px;
	}
}

/* ==== END changeHeightMixin in common/_mixins.sass ==== */

@mixin chooseFlightActiveStyles {
	.flightTable .contentRow td.active a {
		@content;
	}
}

@mixin chooseFlightActiveIconStyles {
	.flightTable .contentRow td.active i {
		@content;
	}
}

// DEPRECATED
@mixin setTabletFullWidthAndHeight {
	.chooseFlight__table__item__content__link {
		@include adaptivity(tablet) {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
		}
	}
}

@mixin priceItem {
	.chooseFlight__table__item__content__link {
		@content;
	}
}

@mixin priceItemActive {
	.chooseFlight__table__item__content.active .chooseFlight__table__item__content__link {
		@content;
	}
}

@mixin equalInputHeight($height) {
	/* ==== equalize input height in passengers step ==== */
	.ui-select-passengers .selectize-input {
		padding: 15px 10px;
		height: $height;
	}
}

/* ==== END equalize input height in passengers step ==== */

@mixin transformInputY($yTransform) {
	.ui-select-passengers .selectize-input {
		transform: translate(0, $yTransform);
	}
}

@mixin setBasketPadding($padding) {
	.selectedInfo__i .drop__i {
		@include adaptivity(tablet) {
			padding-left: $padding;
			padding-right: $padding;
		}
	}
}

@mixin disabledCalendarStyles {
	/* Стили для дат на которые нет перелетов */
	.is-disabled .pika-button.pika-day {
		@content;
	}
}

@mixin enabledCalendarStyles {
	/* Стили для доступных дат */
	.pika-button.pika-day {
		@content;
	}
}

@mixin weekendCalendarStyles {
	/* Стили для выходных дней */
	.pika-single
		table
		tbody
		tr
		td:not(.is-disabled):not(.is-startrange):not(.is-today):not(.is-endrange):not(.is-selected):nth-child(6)
		button,
	.pika-single
		table
		tbody
		tr
		td:not(.is-disabled):not(.is-startrange):not(.is-today):not(.is-endrange):not(.is-selected):nth-child(7)
		button {
		@content;
	}
}

@mixin rangeCalendarStyles {
	/* Стили для дат между двумя выбранными */
	.is-inrange .pika-button {
		@content;
	}
}

@mixin rangeStartEndStyles {
	.is-startrange .pika-button.pika-day {
		@content;
	}
}

@mixin unavalibleStyles {
	/* styles for unavailable dates */
	.pika-lendar__availability-highlight .pika-button.unavailable {
		@content;
	}
}

@mixin removePointerDisabledFromCalendar {
	/* Удаляем запрет на клик по дате, на которую нет перелета см #35382#note-24 */
	.pika-lendar__availability-highlight .pika-button.unavailable {
		pointer-events: all;
		cursor: pointer;
	}
}

@mixin calendarHoverStyles {
	/* Стили для ховера на дате в календаре */
	.pika-button.pika-day:hover {
		@content;
	}
}

@mixin calendarSelectedStyles {
	/* Стили для выбранной даты */
	.is-selected .pika-button.pika-day,
	.is-endrange .pika-button.pika-day {
		@content;
	}
}

@mixin setSearchStepsColor($color) {
	.searchSteps ol li {
		color: $color;
		&:after {
			color: $color;
			border-color: $color;
		}
		&.active,
		&.prev {
			background: transparent;
			color: $color;
			&:after {
				color: $color;
				border-color: $color;
			}
		}
		&.active {
			&:before {
				background: $color;
			}
		}
		&.prev {
			a {
				color: $color;
				background-color: transparent;
				&:hover {
					&:after {
						background: $color;
					}
				}
			}
		}
	}
}

@mixin setSearchFormColor($color, $hoverColor) {
	.orderSearchForm,
	.timeTableSearchForm {
		.calendarBtn,
		.arrowDown {
			color: $color;
		}
		.btnTransfer {
			color: $color;
		}
		.btnPlus i {
			color: $color;
		}
		.bonusItem {
			label {
				color: $color;
			}
			.textInp {
				&:focus {
					border-color: $color;
				}
			}
		}
		.valueControl {
			&.active {
				.btnMinus {
					i {
						color: $color;
					}
				}
			}
		}
	}
	.pikaday-top__label {
		color: $color;
	}
	.valueControl {
		&.active {
			a.btnMinus:hover {
				i {
					color: white;
				}
			}
		}
	}
	.searchParams__i .changeParams {
		color: $color;
		&:after {
			color: inherit;
		}
	}
	.btn.btn_noBack,
	.btn_noBack.popup__i__footer__buttons__button,
	.searchResultsControl .btn_noBack.btn_next,
	.invoice .btn-row .btn_noBack.btn,
	.ticketChange .btnRow .btn_noBack.btn {
		color: $color;
		border-color: $color;
	}
	.btn.btn_search,
	.popup__i__footer__buttons__button,
	.searchResultsControl .btn_next,
	.invoice .btn-row .popup__i__footer__buttons__button,
	.invoice .btn-row .searchResultsControl .btn_next,
	.searchResultsControl .invoice .btn-row .btn_next,
	.invoice .btn-row .btn,
	.ticketChange .btnRow .popup__i__footer__buttons__button,
	.ticketChange .btnRow .searchResultsControl .btn_next,
	.searchResultsControl .ticketChange .btnRow .btn_next,
	.ticketChange .btnRow .btn {
		background: $color;
		&:hover {
			background: $hoverColor;
		}
	}
	.orderSearchForm .item.item_transfer .textInp:focus,
	.timeTableSearchForm .item.item_transfer .textInp:focus,
	.orderSearchForm .calendarInp:focus,
	.timeTableSearchForm .calendarInp:focus {
		border-color: $color;
	}
	.orderSearchForm .btnTransfer:hover,
	.timeTableSearchForm .btnTransfer:hover {
		background: $color;
	}
	/* mobile styles */
	.orderSearchForm .pikaday__header_mobile.active {
		background: $color;
	}
}

@mixin setHeaderStyles($primary, $secondary, $third) {
	.header {
		background: $primary;
	}
	.searchSteps {
		background: $secondary;
	}
	.headerRight {
		.userNav__i a {
			margin-top: 5px;
		}
		.selectedInfo {
			background: transparent;
			&.active {
				.selectedInfo__i {
					box-shadow: none;
					> a {
						color: $third;
					}
				}
			}
			&__i {
				> a {
					color: #4d4d4d;
				}
			}
		}
	}
	.userNav__i .ui-select-header .selectize-input {
		margin-top: -6px;
	}
}

@mixin calendarPrevNextButtons {
	.pika-prev,
	.pika-next {
		@content;
	}
}

@mixin setSearchVariantsColor($primary, $secondary, $textColor: white, $colorSet: initial) {
	.chooseFlight .flightWay a {
		color: $primary;
		span {
			&:first-child {
				&:after {
					color: inherit;
				}
			}
		}
	}
	.chooseDate {
		.control.control_next,
		.control.control_prev {
			&:after {
				color: $primary;
			}
		}
		.tableWrap {
			table {
				td {
					&.active {
						background: $primary;
						a {
							color: $textColor;
							strong {
								color: $textColor;
							}
						}
					}
				}
			}
		}
	}
	.flightTable {
		table > tbody > tr.headrow {
			@for $index from 1 through length($colorSet) {
				$color: nth($colorSet, $index);
				$index: $index + 1;
				> td:nth-child(#{$index}) {
					background: $color;
				}
			}
		}
		.timeTable {
			a {
				color: $primary;
				border-color: inherit;
			}
		}
		.contentRow {
			td {
				&.active {
					a {
						background: $primary;
					}
				}
			}
		}
		.columnTitle {
			.name {
				a {
					color: white;
					border-color: inherit;
				}
			}
		}
	}
	.compareTableWrap {
		/* Таблица сравнения тарифов */
		table tr:first-child {
			@for $index from 1 through length($colorSet) {
				$color: nth($colorSet, $index);
				$index: $index + 1;
				td:nth-child(#{$index}) {
					background: $color;
				}
			}
		}
	}
	.popup_detail {
		.route_item {
			.route_container {
				.route_table {
					.dep,
					.arr {
						&__point_city,
						&__time {
							color: $primary;
						}
					}
				}
			}
		}
	}
	.chooseResult__i,
	.searchResultsPlain__i {
		.route_item {
			.route_container {
				.route_table {
					.dep,
					.arr {
						&__time {
							color: $primary;
						}
						&__point_city {
							color: $primary;
						}
					}
				}
			}
		}
		.flight {
			border-color: $primary;
		}
		.passengers {
			a {
				&:after {
					color: $primary;
				}
			}
		}
		.total {
			p {
				a {
					color: $primary;
					border-color: inherit;
				}
			}
		}
	}
	/* mobile styles */
	.selectDate {
		.item {
			&.active {
				background: $primary;
				a {
					color: white;
					.price,
					.date,
					.date_text {
						color: inherit;
					}
				}
			}
		}
	}
	.chooseFlight {
		&__list {
			&__item {
				&__label {
					> span {
						border-color: $primary;
					}
				}
				&__content {
					&__bottom {
						&__link {
							a {
								color: $primary;
								border-color: inherit;
							}
						}
					}
				}
			}
		}
	}
	.flightTableWrap {
		.title {
			&__i {
				a {
					color: $primary;
				}
			}
		}
	}
	.selectDate .control [class*='icon_'] {
		color: $primary;
	}
}

@mixin setSearchVariantsColorMobile($selectedColor, $selectedHoverColor, $textColor, $colorSet) {
	@include priceItemActive {
		background: $selectedColor;
		&:hover {
			background: $selectedHoverColor;
		}
	}

	.flightTable {
		.chooseFlight__table__item__columnTitle .name a {
			color: $textColor;
		}
		@for $index from 1 through length($colorSet) {
			$color: nth($colorSet, $index);
			.chooseFlight__table__item:nth-of-type(#{$index}) .chooseFlight__table__item__columnTitle {
				background-color: $color;
			}
			$index: $index + 1;
		}
	}
}

@mixin passengersInfo($primaryColor, $secondaryColor, $textColor) {
	.passengersInfo {
		h2 {
			color: $primaryColor;
		}
		.login {
			a {
				color: $primaryColor;
				border-color: inherit;
				&:after {
					border-color: inherit;
				}
			}
		}
		.itemName {
			color: $primaryColor;
			&__number {
				color: $primaryColor;
			}
		}
		input[type='text']:focus,
		input[type='password']:focus,
		input[type='tel']:focus,
		input[type='email']:focus,
		.selectize-input.focus {
			border-color: $primaryColor !important;
		}
		.ui-select-choices-row:hover,
		.selectize-dropdown .active {
			background: rgba($textColor, 0.7) !important;
		}
	}
	.iconfirm {
		.customCheckbox {
			span {
				border-color: $secondaryColor;
			}
			input {
				&:checked {
					+ span:after {
						color: $primaryColor;
					}
				}
			}
		}
	}
	.btn.btn_back {
		color: $primaryColor;
		border-color: $primaryColor;
		&:hover {
			color: white;
			background: $primaryColor;
		}
	}
	.ui-select-passengers-header {
		.selectize-input,
		.selectize-input.focus {
			color: $primaryColor !important;
		}
		.ui-select-placeholder.text-muted {
			color: $primaryColor;
		}
	}
}

@mixin addServices($primaryColor, $secondaryColor, $thirdColor, $shadowColor, $textColor) {
	.checkoutServices__i {
		.title {
			color: $primaryColor;
		}
		.list {
			> .item {
				&.active {
					.top {
						.iconBox {
							background: $primaryColor;
						}
					}
				}
				.top {
					background: $thirdColor;
					.iconBox {
						background: $primaryColor;
					}
					.btn {
						color: $thirdColor;
						border-color: $thirdColor;
					}
				}
			}
		}
		/* mobile styles */
		.row {
			&:first-child {
				background: $thirdColor;
			}
			background: $primaryColor;
		}
	}
	.radioBtn {
		input:checked + span {
			border-color: $thirdColor;
			&:after {
				background: $thirdColor;
			}
		}
	}
	.payMethods a {
		color: $thirdColor;
		border-color: inherit;
	}
}

@mixin orderInfo($primaryColor, $secondaryColor, $thirdColor, $fourthColor) {
	.invoiceInfo {
		.orderNum {
			color: $primaryColor;
		}
	}
	.invoiceBack a {
		color: $primaryColor;
	}
	.invoiceServicesSelected {
		.item {
			.item__i {
				> p strong {
					color: $primaryColor;
				}
			}
		}
	}
	.invoiceDrops {
		.title {
			background: $secondaryColor;
			a {
				color: $primaryColor;
			}
		}
		.circle {
			i {
				color: $primaryColor;
			}
		}
	}
}

@mixin searchOrderPage($primaryColor, $secondaryColor, $thirdColor, $fourthColor) {
	.otherServices {
		background: #f2f2f2;
		margin-top: 5px;
		.btn {
			background: $primaryColor;
			color: white;
			&:hover {
				background: $thirdColor;
			}
		}
	}
	.invoice {
		.btn-row {
			.btn {
				border: none;
			}
		}
	}
	.btn_receipt_cont {
		a.show_receipt,
		a.send_receipt {
			background-color: $primaryColor;
			color: white;
			&:hover {
				background-color: $thirdColor;
			}
		}
		a.send_receipt {
			background-image: $mailIcon;
		}
	}
	.passengerInvoiceInfo {
		strong {
			color: $primaryColor;
		}
	}
}

@mixin chooseFlightFullWidth {
	.chooseFlight__table__item__content__link {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
	}
}

@mixin chooseFlightFullWidthAndHeight {
	.flightTable .contentRow > td {
		&:not(.firstCol) {
			a {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				@content;
			}
		}
	}
}

@mixin colorizeSegmentPricesCalendar($backgroundColor, $fontColor) {
	/* colorize segment prices calendar */
	.selectDate .item.active a {
		background: $backgroundColor;
		color: $fontColor;
		.date_text {
			color: $fontColor;
		}
	}
}

@mixin segmentPricesCalendarStyles($forActive) {
	@if $forActive == true {
		.selectDate .item.active a {
			@content;
		}
	} @else {
		.selectDate .item a {
			@content;
		}
	}
}

@mixin languageMobileColor($color, $mutedColor) {
	/* https://yadi.sk/i/all5Y-nKxu4qKA */
	.ui-select-header.ui-select-header-mobile .selectize-input {
		.ui-select-match {
			color: $color;
		}
		&:after {
			border-color: $color transparent transparent transparent !important;
		}
	}
	.ui-select-header .ui-select-placeholder.text-muted {
		@include adaptivity(tablet) {
			color: $mutedColor;
		}
	}
}

@mixin colorizeBrandTable($colorSet) {
	.flightTable {
		table > tbody > tr.headrow {
			@for $index from 1 through length($colorSet) {
				$color: nth($colorSet, $index);
				$index: $index + 1;
				> td:nth-child(#{$index}) {
					background: $color;
				}
			}
		}
		.chooseFlight {
			&__table {
				@for $index from 1 through length($colorSet) {
					$color: nth($colorSet, $index);
					$index: $index;
					&__item:nth-of-type(#{$index}) {
						@include adaptivity(tablet) {
							.chooseFlight__table__item__columnTitle {
								background: $color;
							}
						}
					}
				}
			}
		}
	}
}

@mixin i18n($selector, $ru: '', $en: '') {
	@at-root html[lang='ru'] {
		#{$selector} {
			content: $ru;
		}
	}
	@at-root html[lang='en'] {
		#{$selector} {
			content: $en;
		}
	}
}

/* ==== Этим миксином можно перечеркнуть иконку в бренде ==== */

@mixin iconCross($content, $color) {
	font-family: 'OpenSkyIcons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;

	&:before {
		content: $content;
		color: $color;
	}

	&:after {
		position: absolute;
		display: inline-block;
		left: 55%;
		top: 50%;
		width: 30px;
		height: 2px;
		background: $color;
		content: '';
		transform: translate(-50%, -50%) rotate(-45deg);
		transform-origin: center;
	}
}

/* ==== END Этим миксином можно перечеркнуть иконку в бренде ==== */

// nordwind specific mixin
@mixin icon($url, $width: 16px, $height: 16px) {
	display: inline-block;
	width: $width;
	height: $height;
	background: $url center;
	background-size: contain;
}

/* ==== Mixin for changing color of buttons in geolocation popup ==== */

@mixin geolocationButtonsColor($primaryColor, $secondaryColor, $fontColor) {
	.common-warning__footer {
		.btn.is-yellow,
		.btn.is-yellow:hover {
			background: $primaryColor;
			color: $fontColor;
		}

		.btn.is-blue,
		.btn.is-blue:hover {
			background: $secondaryColor;
			color: $fontColor;
		}
	}
}

/* ==== END Mixin for changing color of buttons in geolocation popup ==== */

/* ==== Mixin for best deal label ==== */

@mixin cropBestDeal($polygonParameters, $top, $width, $right) {
	.econom_type2,
	.econom_type3,
	.business_type1 {
		overflow: visible;
	}

	.flightTable {
		.columnTitle {
			.best {
				-webkit-clip-path: $polygonParameters;
				clip-path: $polygonParameters;
				top: $top !important;
				width: $width;
				right: $right;

				&:before,
				&:after {
					display: none;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						/* IE10+ styles */
						top: -20px !important;
					}
				}

				@content;
			}
		}
	}
}

/* ==== END Mixin for best deal label ==== */

/* ==== floating labels ==== */

@mixin floatingLabels() {
	.inp-animate-outer {
		margin-top: 16px;
		position: relative;

		input {
			font-size: 0.9em;
			@include placeholder {
				color: transparent;
			}
		}
	}

	.inp-animate-label {
		position: absolute;
		top: 0;
	}

	.passengersInfo input[type='text'],
	.passengersInfo input[type='password'],
	.passengersInfo input[type='tel'],
	.passengersInfo input[type='email'] {
		height: 40px;
	}

	.inp-animate-placeholder:focus ~ .inp-animate-label > span,
	.inp-animate-placeholder.ng-not-empty ~ .inp-animate-label > span,
	.ui-select-passengers.ng-valid ~ .inp-animate-label > span {
		transition: transform 0.3s;
		transform: translate3d(-10px, -30px, 0);
		border: none;
		background: none;
	}

	.inp-animate-label {
		top: 0;
		position: absolute;
		width: 100%;
		text-align: left;
		pointer-events: none;
		display: block;
		user-select: none;

		span {
			padding: 11px 10px;
			display: block;
			border: 1px solid #e3e3e3;
			background: white;
			border-radius: 3px;
			font-size: 0.9em;
			color: #666666;
		}
	}
}

/* ==== END floating labels ==== */
